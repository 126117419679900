"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Card, CardTitle, CardContent, CardDescription } from "@/components/ui/card";

const TypingText = () => {
  const words = ["Anyone", "Collectors", "Traders", "Investors"];
  const [wordIndex, setWordIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (letterIndex === words[wordIndex].length) {
      setTimeout(() => setDeleting(true), 1300);
    } else {
      setTimeout(() => setLetterIndex(letterIndex + 1), 75);
    }
  }, [letterIndex, wordIndex]);

  useEffect(() => {
    if (deleting && letterIndex === 0) {
      setWordIndex((wordIndex + 1) % words.length);
      setDeleting(false);
    } else if (deleting) {
      setTimeout(() => setLetterIndex(letterIndex - 1), 75);
    }
  }, [deleting, letterIndex, wordIndex]);

  return <span>{words[wordIndex].slice(0, letterIndex)}</span>;
};

export function HeroBetween() {
  const features = [
    {
      title: "Combining AI analytics with realtime data and modern charting.",
      description: "Utilizing AI to analyze market trends and real-time data, WatchInspect offers predictive insights and advanced charting using TradingView."
    },
    {
      title: "Get notified whenever new watches hit the market.",
      description: "Collectors can stay ahead with blazingly fast notifications on trends, pricing, and new listings tailored to their preferences."
    },
    {
      title: "Efficient crosslisting software for sellers to speed up productivity.",
      description: "Simplifies the selling process by allowing listings on popular marketplaces like eBay, Chrono24, or Reddit - all at the same time, with just a few clicks."
    },
    {
      title: "Our own curated marketplace with vintage and modern watches.",
      description: "WatchInspect offers a curated selection of vintage and modern watches, all authenticated by experts. The marketplace ensures competitive pricing, detailed histories, and a trusted buying experience with buyer protection and community reviews."
    }
  ];

  return (
    <main className="w-full min-h-screen bg-black relative overflow-hidden">
      <div
        className="absolute inset-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{
          background: 'radial-gradient(circle at center, rgba(0, 80, 255, 0.15), transparent 60%)',
          filter: 'blur(100px)',
          animation: 'pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite',
          width: '800px',
          height: '800px'
        }}
      />

      <section className="container mx-auto px-4 py-16 relative z-10">
        <div className="text-center mb-16">
          <div className="relative inline-block">
            <Image
              src="/fuzz.svg"
              alt="Fuzz"
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-60"
              height={300}
              width={300}
            />
            <div className="relative z-10 space-y-4">
              <h1 className="text-5xl md:text-6xl font-bold text-white">
                Free Tracking Software
              </h1>
              <h1 className="text-5xl md:text-6xl font-bold text-white">
                For <span className="text-blue-500"><TypingText /></span>
              </h1>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-7xl mx-auto">
          {features.map((feature, index) => (
            <Card
              key={index}
              className="group bg-[#161B22]/90 backdrop-blur-sm border border-white/10 rounded-2xl 
                transition-all duration-300
                hover:border-blue-500/50 hover:shadow-[0_0_30px_rgba(0,120,255,0.3)]
                hover:scale-[1.02]
                relative"
              style={{
                transformStyle: 'preserve-3d',
              }}
            >
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-blue-500/0 to-blue-500/0 
                group-hover:from-blue-500/10 group-hover:to-purple-500/10 
                transition-all duration-300"
              />
              <div className="p-8 relative z-10">
                <CardTitle className="text-2xl font-bold text-white mb-4 group-hover:text-blue-400 transition-colors duration-300">
                  {feature.title}
                </CardTitle>
                <CardDescription className="text-gray-400 text-lg group-hover:text-gray-300 transition-colors duration-300">
                  {feature.description}
                </CardDescription>
              </div>
            </Card>
          ))}
        </div>
      </section>
    </main>
  );
}