import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import Image from "next/image";

interface DiscountCardProps {
  onClose: () => void;
}

export const DiscountCard = ({ onClose }: DiscountCardProps) => {
  const handleCloseClick = () => {
    onClose();
  };

  return (
    <div key="1" className="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center p-4">
      <div className="flex gap-0">
        <div className="bg-white shadow-xl w-full max-w-md p-6 space-y-6 rounded-l-lg">
          <button className="text-black absolute top-4 right-4" onClick={handleCloseClick}>
            <PanelTopCloseIcon className="w-6 h-6" />
          </button>
          <h2 className="text-xl font-bold text-center">UNLOCK</h2>
          <div className="text-center space-y-2">
            <p className="text-6xl font-extrabold">25% OFF</p>
            <p className="font-semibold">Thank you for being one of our eBay shoppers!</p>
          </div>
          <div className="text-center font-medium">
            You&apos;ve unlocked a discount coupon!
          </div>
          <div className="flex flex-col items-center space-y-4">
            <label className="sr-only" htmlFor="email">
              Enter your email address
            </label>
            <Input id="email" placeholder="enter email address to claim" />
            <Button>Continue</Button>
            <Button className="text-sm" variant="ghost" onClick={handleCloseClick}>
              I don&apos;t want a discount
            </Button>
          </div>
        </div>
        <div className="w-[400px] h-[600px] overflow-hidden">
          <Image
            alt="Placeholder Image"
            className="w-full h-full object-cover"
            height={600}
            src="/lady-sub.jpg"
            style={{
              aspectRatio: "400/600",
              objectFit: "cover",
            }}
            width={400}
          />
        </div>
      </div>
    </div>
  )
}

function PanelTopCloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
      <line x1="3" x2="21" y1="9" y2="9" />
      <path d="m9 16 3-3 3 3" />
    </svg>
  )
}