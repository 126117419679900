"use client";
import { Card, CardContent } from "@/components/ui/card";
import Link from "next/link";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import {
  ChevronRight,
  Code,
  Smartphone,
  Computer,
} from "lucide-react";

type HeroTopProps = {
  setIsSticky: React.Dispatch<React.SetStateAction<boolean>>;
};

export function HeroTop({ setIsSticky }: HeroTopProps) {
  return (
    <main className="w-full min-h-screen bg-black relative overflow-hidden">
      {/* Background Blur Effect */}
      <div
        className="absolute inset-0"
        style={{
          background: 'radial-gradient(circle at center, rgba(0, 80, 255, 0.15), transparent 60%)',
          filter: 'blur(100px)',
          animation: 'pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite'
        }}
      />

      <div className="container mx-auto px-4 py-16 relative z-10">
        {/* Header text */}
        <div className="text-center mb-12">
          <h1 className="text-6xl font-bold text-white mb-4">
            Price your watches <span className="text-blue-400">now.</span>
          </h1>
          <p className="text-gray-400 text-xl">
            Real-time market data for luxury timepieces
          </p>
        </div>

        {/* Market Overview Card */}
        <Card className="bg-[#1e1e1e]/90 backdrop-blur-sm border border-white/10 rounded-2xl shadow-lg hover:shadow-blue-500/20 transition-all duration-300">
          <CardContent className="p-8">
            {/* Header */}
            <Link href="/dashboard">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-2xl font-bold text-white flex items-center">
                  Market summary
                  <ChevronRight className="h-6 w-6 ml-2" />
                </h2>
                <div className="flex items-center space-x-4">
                  <Code className="h-5 w-5 text-gray-400" />
                  <Smartphone className="h-5 w-5 text-gray-400" />
                  <Computer className="h-5 w-5 text-gray-400" />
                </div>
              </div>
            </Link>

            {/* Filter Buttons */}
            <div className="flex flex-wrap gap-2 mb-8">
              <Button className="bg-blue-500 hover:bg-blue-600 text-white">Indices</Button>
              {['Rolex', 'Patek', 'Grand Seiko', 'Cartier', 'Gold', 'Silver'].map((item) => (
                <Button
                  key={item}
                  variant="ghost"
                  className="text-gray-300 hover:bg-white/20 hover:text-white"
                >
                  {item}
                </Button>
              ))}
            </div>

            {/* Brand Cards */}
            <div className="grid grid-cols-3 gap-6 mb-8">
              {['Rolex', 'Audemars', 'Omega'].map((brand) => (
                <Link href="/dashboard" key={brand}>
                  <div
                    className="bg-white rounded-xl p-4 transition-all duration-300 hover:shadow-lg hover:opacity-90"
                    style={{
                      boxShadow: '0 0 10px 0 rgba(0, 80, 255, 0.2)',
                    }}
                  >
                    <div className="relative h-24 w-full">
                      <Image
                        alt={`${brand} Logo`}
                        className="object-contain"
                        fill
                        src={`/${brand.toLowerCase()}.svg`}
                      />
                    </div>
                  </div>
                </Link>
              ))}
            </div>

            {/* Time Filter */}
            <div className="flex justify-center gap-2">
              {['1D', '1M', '3M', '1Y', '5Y', 'All'].map((period) => (
                <Button
                  key={period}
                  variant="ghost"
                  className="text-gray-300 hover:bg-white/20 hover:text-white"
                >
                  {period}
                </Button>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </main>
  );
}