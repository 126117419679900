import { MarketPreview } from "../../component/market-preview";
export function MarketplacePreview() {
    return (
        <main className="w-full relative z-10">
            <section className="min-h-screen flex w-full justify-center">
                <div className="min-h-screen flex flex-col text-white p-4 w-full overflow-hidden">
                    <MarketPreview />
                </div>
            </section>
        </main>
    );
}