//app/page.tsx
"use client";

import { Suspense, useState, useEffect } from 'react';
import { HeroTop } from "@/components/component/home/hero-top";
import { HeroMid } from "@/components/component/home/hero-mid";
import { HeroBetween } from "@/components/component/home/hero-between";
import { HeroBottom } from "@/components/component/home/hero-bottom";
import { MarketplacePreview } from "@/components/component/home/marketplace-preview";
import { Navbar1 } from "@/components/component/navbar/navbar";
import { Footer } from "@/components/component/footer";
import { SupportMain } from "@/components/component/support/support-main";
import { SupportButton } from "@/components/component/support/SupportButton";
import { DiscountCard } from "@/components/component/discount-card";
import { AnimatePresence } from 'framer-motion';
import hexBackground from '../public/hex.svg';

export default function Component() {
  const [isSticky, setIsSticky] = useState(false);
  const [showSupportMain, setShowSupportMain] = useState(false);

  const handleSupportButtonClick = () => {
    setShowSupportMain(!showSupportMain);
  };

  const handleSupportMainClose = () => {
    setShowSupportMain(false);
  };

  return (
    <div className="min-h-screen flex flex-col overflow-hidden bg-black relative scrollable-content"
      style={{
        filter: "contrast(110%) saturate(130%)",
      }}>
      <div
        className="absolute top-10 -right-1/4 w-3/4 h-3/4 bg-no-repeat pointer-events-none z-0"
        style={{
          backgroundImage: `url(${hexBackground.src})`,
          backgroundPosition: 'top right',
          backgroundSize: 'contain',
          backgroundOrigin: 'content-box',
          padding: '10px',
        }}
      />

      <Navbar1 isSticky={isSticky} />
      <MarketplacePreview />
      <HeroTop setIsSticky={setIsSticky} />
      <HeroMid />
      <HeroBetween />
      <HeroBottom />
      <Footer />

      <Suspense fallback={<div>Loading...</div>}>
        <DiscountCardWrapper />
      </Suspense>

      <AnimatePresence>
        {showSupportMain && <SupportMain onClose={handleSupportMainClose} />}
      </AnimatePresence>

      <SupportButton onClick={handleSupportButtonClick} isVisible={!showSupportMain} />
    </div>
  );
}

function DiscountCardWrapper() {
  const [showDiscountCard, setShowDiscountCard] = useState(false);

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.slice(1));
    const couponCode = hashParams.get('coupon');
    setShowDiscountCard(couponCode !== null);
  }, []);

  const handleDiscountCardClose = () => {
    setShowDiscountCard(false);
    window.history.pushState({}, document.title, '/');
  };

  return (
    <AnimatePresence>
      {showDiscountCard && <DiscountCard onClose={handleDiscountCardClose} />}
    </AnimatePresence>
  );
}