"use client";

import { useState } from "react";
import { ChevronRight } from "lucide-react";

export function HeroBottom() {
  const [isButtonHovered, setButtonHovered] = useState(false);

  return (
    <main className="min-h-screen bg-black relative overflow-hidden">
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url('/market-movements.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          opacity: 0.2
        }}
      />

      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="text-center space-y-12">
          <h1
            className={`text-7xl font-bold text-white tracking-tight ${isButtonHovered ? "animate-bounce-slow" : "animate-pulse"
              }`}
          >
            Market Movements <br />
            At Every Tick <span className="text-blue-400 animate-bounce">.</span>
          </h1>

          <div className="flex flex-col items-center">
            <button
              onMouseEnter={() => setButtonHovered(true)}
              onMouseLeave={() => setButtonHovered(false)}
              className="group inline-flex items-center px-8 py-4 rounded-xl
                bg-gradient-to-r from-blue-500 via-blue-600 to-blue-500
                hover:shadow-lg hover:shadow-blue-500/25
                transition-all duration-300 transform hover:scale-105"
            >
              <span className="text-white font-semibold text-lg">Get Started</span>
              <ChevronRight className="ml-2 h-5 w-5 text-white 
                transform group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}