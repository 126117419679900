// components/OptimizedImage.tsx
import React, { useState, useEffect, useCallback } from 'react';
import Image, { ImageProps } from 'next/image';
import { Loader2, Image as ImageIcon } from 'lucide-react';

interface OptimizedImageProps {
    src: string;
    alt: string;
    width: number;
    height: number;
    className?: string;
    priority?: boolean;
    sizes?: string;
    quality?: number;
    onLoadComplete?: () => void;
}

const OptimizedImage: React.FC<OptimizedImageProps> = ({
    src,
    alt,
    width,
    height,
    className = "",
    priority = false,
    sizes = "100vw",
    quality = 75,
    onLoadComplete
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [imageSrc, setImageSrc] = useState<string>(src);

    // Preload image for better performance
    useEffect(() => {
        if (!src || typeof window === 'undefined') return;

        const preloadImage = new window.Image();
        preloadImage.src = src;

        return () => {
            preloadImage.src = '';
        };
    }, [src]);

    const handleLoadComplete = useCallback(() => {
        setIsLoading(false);
        if (onLoadComplete) {
            onLoadComplete();
        }
    }, [onLoadComplete]);

    const handleError = useCallback(() => {
        console.error('Image load error:', imageSrc);
        setHasError(true);
        setIsLoading(false);
        if (onLoadComplete) {
            onLoadComplete(); // Still mark as complete even on error
        }
    }, [imageSrc, onLoadComplete]);

    useEffect(() => {
        if (!src) {
            setHasError(true);
            return;
        }
        setImageSrc(src);
        setIsLoading(true);
        setHasError(false);
    }, [src]);

    const isExternalUrl = src?.startsWith('http') && !src?.includes('localhost');

    const renderPlaceholder = useCallback(() => (
        <div className="absolute inset-0 flex items-center justify-center bg-slate-800">
            <ImageIcon className="w-12 h-12 text-slate-600" />
        </div>
    ), []);

    const blurDataURL = `data:image/svg+xml;base64,${Buffer.from(
        `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="100%" fill="#1e293b"/>
        </svg>`
    ).toString('base64')}`;

    if (hasError) {
        return (
            <div className={`relative ${className}`}>
                {renderPlaceholder()}
            </div>
        );
    }

    if (isExternalUrl) {
        return (
            <div className={`relative overflow-hidden ${className}`}>
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-slate-800/50 backdrop-blur-sm">
                        <Loader2 className="w-6 h-6 animate-spin text-blue-500" />
                    </div>
                )}
                {hasError && renderPlaceholder()}

                <img
                    src={imageSrc}
                    alt={alt}
                    className={`w-full h-full object-cover transform-gpu transition-opacity duration-300 ${isLoading || hasError ? 'opacity-0' : 'opacity-100'
                        }`}
                    onLoad={handleLoadComplete}
                    onError={handleError}
                    loading={priority ? "eager" : "lazy"}
                    decoding="async"
                />
            </div>
        );
    }

    const imageProps: ImageProps = {
        src: imageSrc,
        alt,
        width,
        height,
        className: `transform-gpu transition-opacity duration-300 ${isLoading || hasError ? 'opacity-0' : 'opacity-100'
            }`,
        onLoad: handleLoadComplete,
        onError: handleError,
        priority,
        sizes,
        quality,
        loading: priority ? "eager" : "lazy",
        placeholder: "blur",
        blurDataURL
    };

    return (
        <div className={`relative overflow-hidden ${className}`}>
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-slate-800/50 backdrop-blur-sm">
                    <Loader2 className="w-6 h-6 animate-spin text-blue-500" />
                </div>
            )}
            {hasError && renderPlaceholder()}
            <Image {...imageProps} />
        </div>
    );
};

export default OptimizedImage;