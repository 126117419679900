import { useEffect, useState } from 'react';
import { Card } from "@/components/ui/card";
import Link from 'next/link';
import { GeistSans } from 'geist/font/sans';
import OptimizedImage from "../../../utils/OptimizedImage";

interface Watch {
  _id: string;
  name: string;
  price: number;
  image: string;
  sold?: boolean;
}

const placeholderWatches: Watch[] = [
  {
    _id: "placeholder1",
    name: "Rolex Submariner Date Watch",
    price: 13950,
    image: "/placeholder.svg"
  },
  {
    _id: "placeholder2",
    name: "Omega Speedmaster Moonwatch Professional Watch",
    price: 6650,
    image: "/placeholder.svg"
  },
  {
    _id: "placeholder3",
    name: "Patek Philippe Calatrava Joaillerie Ladies Watch",
    price: 29950,
    image: "/placeholder.svg"
  },
  {
    _id: "placeholder4",
    name: "TAG Heuer Aquaracer Professional 300 GMT Watch",
    price: 2350,
    image: "/placeholder.svg"
  }
];

export function MarketPreview() {
  const [watches, setWatches] = useState<Watch[]>(placeholderWatches);

  useEffect(() => {
    async function fetchWatches() {
      try {
        const response = await fetch('/api/marketplace/listings?sort=new-old');
        const data = await response.json();
        const availableWatches = data.watches.filter((watch: Watch) => !watch.sold);
        setWatches(availableWatches.slice(0, 4));
      } catch (error) {
        console.error("Error fetching watches:", error);
      }
    }
    fetchWatches();
  }, []);

  function truncateName(name: string, maxLength: number = 40): string {
    if (name.length <= maxLength) return name;
    return name.slice(0, maxLength - 3) + '...';
  }

  return (
    <div className={`w-full ${GeistSans.className} relative z-10`}>
      <div className="text-center mb-4 md:mb-8">
        <h1 className="text-2xl md:text-4xl font-bold">
          <span className="text-white text-5xl ">Luxury watches</span>,{" "}
          <span className="text-blue-500 text-5xl ">from a trusted watch dealer.</span>
        </h1>
      </div>
      <div className="text-center mb-4">
        <h2 className="text-xl md:text-2xl font-semibold">NEW WATCH ARRIVALS</h2>
      </div>
      <div className="px-4 md:px-12 lg:px-16 max-w-7xl mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-3">
          {watches.slice(0, 4).map((watch, index) => (
            <Card key={watch._id} className="text-center overflow-hidden md:max-w-[220px] mx-auto">
              <div className="relative w-full h-40 md:h-56 overflow-hidden group">
                <OptimizedImage
                  src={watch.image || "/placeholder.svg"}
                  alt={watch.name}
                  width={200}
                  height={320}
                  className="w-full h-full object-contain transition-transform duration-300 ease-in-out group-hover:scale-105 md:object-cover"
                  priority={index === 0}
                  sizes="(max-width: 768px) 45vw, 20vw"
                  quality={85}
                />
              </div>
              <div className="p-2">
                <div className="text-xs md:text-sm text-gray-500 h-8 overflow-hidden">
                  {truncateName(watch.name, 25)}
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-6 md:mt-8">
        <Link href="/marketplace">
          <button className="px-6 md:px-10 py-2 rounded-full bg-gradient-to-b from-blue-500 to-blue-600 text-white focus:ring-2 focus:ring-blue-400 hover:shadow-xl transition duration-200 text-sm md:text-base">
            Enter Marketplace
          </button>
        </Link>
      </div>
    </div>
  );
}