// this is for user - not the admin, the user will message through here.
// src/components/component/messenger-interface.tsx

// MessengerInterface.tsx
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Image from 'next/image';
import { Card } from "@/components/ui/card";
import { motion } from 'framer-motion';


interface Message {
    id: number;
    text: string;
    userId: string;
    userName: string;
    sender: 'user' | 'admin';
    adminResponses?: Message[];
}

interface MessengerInterfaceProps {
    onBack: () => void;
    onMessageSend: (message: Message) => Promise<void>;
}

export function MessengerInterface({ onBack, onMessageSend }: MessengerInterfaceProps) {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputText, setInputText] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleSendMessage = async () => {
        if (inputText.trim() !== '') {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const newMessage: Message = {
                id: messages.length + 1,
                text: inputText,
                sender: 'user',
                userId: user.id,
                userName: user.name,
            };
            setMessages([...messages, newMessage]);
            setInputText('');
            await onMessageSend(newMessage);
        }
    };

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        setIsLoggedIn(!!user.id);

        if (user.id) {
            fetchMessages();
            const intervalId = setInterval(fetchMessages, 5000);
            return () => clearInterval(intervalId);
        }
    }, []);

    const fetchMessages = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const response = await axios.get(`/api/support/messages/${user.id}`);
            setMessages(response.data);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    if (!isLoggedIn) {
        return (
            <div className={`flex flex-col ${isMobile ? 'h-[70vh]' : 'h-[510px]'} items-center justify-center bg-slate-900`}>
                <div className="text-center space-y-4">
                    <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-slate-400">
                        Login Required
                    </h2>
                    <p className="text-slate-400 mb-4">Please login to continue</p>
                    <button
                        className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 text-white px-6 py-3 rounded-xl shadow-lg transition-all duration-300 hover:shadow-blue-500/25 hover:scale-[1.02]"
                        onClick={() => window.location.href = 'https://watchinspect.com/auth'}
                    >
                        Login to Send Messages
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className={`flex flex-col ${isMobile ? 'h-[90vh]' : 'h-[510px]'} bg-slate-900`}>
            <div className="bg-slate-800/50 backdrop-blur-sm p-4 flex items-center justify-between border-b border-slate-700/50">
                <button
                    onClick={onBack}
                    className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                >
                    <ChevronLeftIcon className="h-6 w-6" />
                </button>
                <div className="flex items-center space-x-2">
                    <Image
                        alt="WATCH INSPECT logo"
                        className="h-8 w-8"
                        height="40"
                        src="/512white.png"
                        style={{
                            aspectRatio: "40/40",
                            objectFit: "cover",
                        }}
                        width="40"
                    />
                    <span className="text-slate-200 font-semibold">WATCH INSPECT</span>
                </div>
                <div className="relative">
                    <span className="absolute -top-0 -right-0 h-3 w-3 rounded-full bg-green-500 animate-pulse" />
                    <Image
                        alt="Profile Picture"
                        className="h-10 w-10 rounded-full ring-2 ring-slate-700/50 transition-all duration-300 hover:ring-blue-500/50"
                        height="50"
                        src="/tristan_HEADSHOT.jpg"
                        style={{
                            aspectRatio: "50/50",
                            objectFit: "cover",
                        }}
                        width="50"
                    />
                </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4 space-y-4">
                {messages.map((message, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className={`flex items-center ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`${message.sender === 'user'
                                    ? 'bg-blue-600 text-white'
                                    : 'bg-slate-800/50 backdrop-blur-sm text-slate-200'
                                } rounded-xl p-3 max-w-[80%] shadow-lg ${message.sender === 'user'
                                    ? 'hover:bg-blue-500'
                                    : 'hover:bg-slate-700/50'
                                } transition-all duration-300`}>
                                {message.text}
                            </div>
                        </div>
                        {message.adminResponses?.map((adminResponse, adminIndex) => (
                            <div key={adminIndex} className="flex justify-start mt-2">
                                <div className="bg-slate-800/50 backdrop-blur-sm text-slate-200 rounded-xl p-3 max-w-[80%] shadow-lg hover:bg-slate-700/50 transition-all duration-300">
                                    {adminResponse.text}
                                </div>
                            </div>
                        ))}
                    </motion.div>
                ))}
                <div ref={messagesEndRef} />
            </div>

            <div className="p-4 bg-slate-800/50 backdrop-blur-sm border-t border-slate-700/50">
                <div className="flex items-center space-x-2">
                    <input
                        className="flex-1 p-3 bg-slate-700/30 text-slate-200 placeholder-slate-400 rounded-xl border border-slate-600/50 focus:outline-none focus:ring-2 focus:ring-blue-500/50 transition-all duration-300"
                        placeholder="Type your message..."
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                handleSendMessage();
                            }
                        }}
                    />
                    <button
                        className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 text-white px-6 py-3 rounded-xl shadow-lg transition-all duration-300 hover:shadow-blue-500/25 hover:scale-[1.02]"
                        onClick={handleSendMessage}
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
}

// Keep all the icon components the same as they were in the original code
function ChevronLeftIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m15 18-6-6 6-6" />
        </svg>
    );
}