// utils/chartProcessing.ts

export interface SimpleData {
    t: number;  // timestamp
    p: number;  // price
}

export interface ChartDataPoint {
    time: string;  // ISO date string
    value: number;
}

// Helper function to calculate EMA for SimpleData
export const calculateEMA = (data: SimpleData[], period: number = 20): number[] => {
    if (!data?.length) return [];

    const k = 2 / (period + 1);
    let ema = data[0].p;
    return data.map(point => {
        ema = point.p * k + ema * (1 - k);
        return ema;
    });
};

// Normalize timestamps to handle duplicate dates
export const normalizeTimeSeries = (data: SimpleData[]): SimpleData[] => {
    const dailyMap = new Map<string, SimpleData>();

    data.forEach(point => {
        const dateStr = new Date(point.t).toISOString().split('T')[0];
        const existing = dailyMap.get(dateStr);

        if (!existing || existing.t < point.t) {
            dailyMap.set(dateStr, point);
        }
    });

    return Array.from(dailyMap.values())
        .sort((a, b) => a.t - b.t);
};

// Process time series data for chart display
export const processTimeSeriesData = (data: SimpleData[], period: number = 20): ChartDataPoint[] => {
    if (!data?.length) return [];

    // First normalize the data to handle duplicate timestamps
    const normalizedData = normalizeTimeSeries(data);

    // Calculate EMA
    const emaValues = calculateEMA(normalizedData, period);

    // Convert to chart format
    return normalizedData.map((point, index) => ({
        time: new Date(point.t).toISOString().split('T')[0],
        value: emaValues[index]
    }));
};

// Get the latest smoothed price
export const getLatestPrice = (data: SimpleData[], period: number = 20): number => {
    if (!data?.length) return 0;

    const normalizedData = normalizeTimeSeries(data);
    const emaValues = calculateEMA(normalizedData, period);

    // Return the last EMA value, rounded to 3 decimal places
    return Number(emaValues[emaValues.length - 1].toFixed(3));
};

// Optional: Keep OHLC processing if you need it
export const processOHLCData = (data: any[], period: number = 20) => {
    const values = data.map(point => ({
        t: new Date(point.time).getTime(),
        p: point.close
    }));

    const normalizedData = normalizeTimeSeries(values);
    const emaValues = calculateEMA(normalizedData, period);

    return data.map((point, index) => ({
        time: point.time,
        open: emaValues[index],
        high: point.high * (emaValues[index] / point.close),
        low: point.low * (emaValues[index] / point.close),
        close: emaValues[index],
        volume: point.volume
    }));
};