import React from 'react';
import { motion } from 'framer-motion';
import { HeadphonesIcon } from 'lucide-react';

interface SupportButtonProps {
    onClick: () => void;
    isVisible: boolean;
}

export function SupportButton({ onClick, isVisible }: SupportButtonProps) {
    if (!isVisible) {
        return null;
    }

    return (
        <motion.div
            className="fixed bottom-8 right-8 z-50"
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <motion.button
                className="relative bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-500 hover:to-blue-600 text-white p-4 rounded-full shadow-lg transition-all duration-300 hover:shadow-blue-500/25 hover:scale-[1.05] focus:outline-none focus:ring-2 focus:ring-blue-500/50 group"
                onClick={onClick}
                whileHover={{ rotate: [0, -10, 10, -5, 5, 0] }}
                transition={{ duration: 0.5 }}
            >
                <span className="absolute -top-1 -right-1 h-4 w-4 z-10">
                    <span className="absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75 animate-ping" />
                    <span className="relative inline-flex rounded-full h-4 w-4 bg-green-500" />
                </span>
                <HeadphonesIcon className="h-6 w-6 transition-transform duration-300 group-hover:scale-110" />
            </motion.button>
        </motion.div>
    );
}