
// src/components/component/support-main.tsx

// SupportMain.tsx
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { MessengerInterface } from './messenger-interface';
import axios from "axios";
import Image from 'next/image';
import { Card } from "@/components/ui/card";

export interface Message {
  id: number;
  text: string;
  userId: string;
  userName: string;
  sender: 'user' | 'admin';
}

interface SupportMainProps {
  onClose: () => void;
}

export function SupportMain({ onClose }: { onClose: () => void }) {
  const [showMessenger, setShowMessenger] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleSendMessageToServer = async (message: Message) => {
    try {
      await axios.post("/api/support/messages", message);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-end p-4">
      <motion.div
        key="1"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.3 }}
        className={`flex flex-col bg-slate-900 shadow-2xl overflow-hidden ${
          isMobile ? 'w-full h-full' : 'w-[400px] h-[600px] mr-4'
        } rounded-2xl border border-slate-700/50`}
      >
        {showMessenger ? (
          <MessengerInterface
            onBack={() => setShowMessenger(false)}
            onMessageSend={handleSendMessageToServer}
          />
        ) : (
          <div className="flex flex-col h-full">
            <div className="bg-slate-800/50 backdrop-blur-sm p-4 flex items-center justify-between border-b border-slate-700/50">
              <div className="flex items-center space-x-2">
                <Image
                  alt="WATCH INSPECT logo"
                  className="h-8 w-8"
                  height="40"
                  src="/512white.png"
                  style={{
                    aspectRatio: "40/40",
                    objectFit: "cover",
                  }}
                  width="40"
                />
                <span className="text-slate-200 font-semibold">WATCH INSPECT</span>
              </div>
              <div className="flex items-center space-x-6">
                <div className="relative">
                  <span className="absolute -top-0 -right-0 h-3 w-3 rounded-full bg-green-500 animate-pulse" />
                  <Image
                    alt="Profile Picture"
                    className="h-10 w-10 rounded-full ring-2 ring-slate-700/50 transition-all duration-300 hover:ring-blue-500/50"
                    height="50"
                    src="/tristan_HEADSHOT.jpg"
                    style={{
                      aspectRatio: "50/50",
                      objectFit: "cover",
                    }}
                    width="50"
                  />
                </div>
                <button
                  aria-label="Close"
                  onClick={onClose}
                  className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                >
                  <XIcon className="h-6 w-6" />
                </button>
              </div>
            </div>

            <div className="bg-gradient-to-b from-slate-800 to-slate-900 p-6 text-center">
              <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-slate-400 mb-2">
                Hi there 👋
              </h2>
              <p className="text-xl text-slate-200 mb-1">How can we help?</p>
              <p className="text-sm text-slate-400">Message us directly below for assistance</p>
            </div>

            <div className="flex-1 overflow-y-auto bg-slate-900/50 backdrop-blur-sm">
              <Card className="m-4 bg-slate-800/50 backdrop-blur-sm border-slate-700/50 shadow-xl transition-all duration-300 hover:shadow-blue-500/10">
                <div className="p-4 space-y-4">
                  <div className="flex items-center p-2 bg-slate-700/30 rounded-lg transition-all duration-300 focus-within:ring-2 focus-within:ring-blue-500/50">
                    <input
                      className="w-full bg-transparent text-slate-200 placeholder-slate-400 focus:outline-none"
                      placeholder="Search for help"
                      type="search"
                    />
                    <MicroscopeIcon className="text-slate-400 h-5 w-5" />
                  </div>

                  {['Watch valuation', 'Authenticity check', 'Market price tracking', 'Latest watch releases', 'Marketplace updates'].map((item) => (
                    <div key={item} className="flex justify-between items-center p-3 rounded-lg hover:bg-slate-700/30 transition-all duration-300 cursor-pointer group">
                      <span className="text-slate-200 group-hover:text-blue-400 transition-colors duration-300">{item}</span>
                      <ChevronRightIcon className="text-slate-400 h-5 w-5 group-hover:text-blue-400 transition-colors duration-300" />
                    </div>
                  ))}
                </div>
              </Card>
            </div>

            <div className="mt-auto border-t border-slate-700/50">
              <button
                className="bg-slate-800/50 backdrop-blur-sm p-4 flex justify-between items-center w-full text-left hover:bg-slate-700/30 transition-all duration-300"
                onClick={() => setShowMessenger(true)}
              >
                <div>
                  <span className="text-lg font-semibold text-slate-200">Send us a message</span>
                  <p className="text-sm text-slate-400">We typically reply in a few minutes</p>
                </div>
                <ChevronRightIcon className="text-slate-400 h-6 w-6" />
              </button>

              <div className="border-t border-slate-700/50 p-4 flex justify-around bg-slate-800/50 backdrop-blur-sm">
                {[
                  { Icon: HomeIcon, label: 'Home' },
                  { Icon: FileQuestionIcon, label: 'Help' },
                  { Icon: TextIcon, label: 'Messages' }
                ].map(({ Icon, label }) => (
                  <div key={label} className="flex flex-col items-center group">
                    <button
                      aria-label={label}
                      className="text-slate-400 hover:text-blue-400 transition-colors duration-300"
                    >
                      <Icon className="h-6 w-6" />
                    </button>
                    <span className="text-xs text-slate-400 mt-1 group-hover:text-blue-400 transition-colors duration-300">
                      {label}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
}

function ChevronRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  )
}


function FileQuestionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
      <path d="M10 10.3c.2-.4.5-.8.9-1a2.1 2.1 0 0 1 2.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2" />
      <path d="M12 17h.01" />
    </svg>
  )
}


function HomeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  )
}


function MicroscopeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 18h8" />
      <path d="M3 22h18" />
      <path d="M14 22a7 7 0 1 0 0-14h-1" />
      <path d="M9 14h2" />
      <path d="M9 12a2 2 0 0 1-2-2V6h6v4a2 2 0 0 1-2 2Z" />
      <path d="M12 6V3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3" />
    </svg>
  )
}


function TextIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 6.1H3" />
      <path d="M21 12.1H3" />
      <path d="M15.1 18H3" />
    </svg>
  )
}


function XIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 6 6 18" />
      <path d="m6 6 12 12" />
    </svg>
  )
}


function ChevronLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  );
}