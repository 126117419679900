import { useRef, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { AvatarImage, Avatar } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { useAuth } from "../../../lib/AuthContext";
import { motion } from "framer-motion";
import { Card, CardContent } from "@/components/ui/card";
import { useRouter } from "next/navigation";
import {
    Watch as WatchIcon,
    Heart as HeartIcon,
    ShoppingBag as ShoppingBagIcon,
    DollarSign as DollarSignIcon,
    Layers as LayersIcon,
    ArrowLeft as ArrowLeftIcon,
    X,
    Shield as ShieldIcon,
    Loader2,
    PlusIcon,
    SettingsIcon,
    BarChart3
} from "lucide-react";

interface ProfilePanelProps {
    onClose: () => void;
}

export function ProfilePanel({ onClose }: ProfilePanelProps) {
    const { user, logout } = useAuth();
    const router = useRouter();
    const panelRef = useRef<HTMLDivElement>(null);
    const [portfolioValue, setPortfolioValue] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const handleAddWatchClick = () => {
        router.push('/portfolio?action=add');
        onClose();
    };

    const getFormattedName = (name?: string) => {
        if (!name) return '';
        return name.charAt(0).toUpperCase() + name.slice(1);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!user?.id || !isInitialLoad) return;

            setIsLoading(true);
            try {
                const [profileRes, portfolioRes] = await Promise.all([
                    fetch('/api/user/profile', {
                        headers: { 'user-id': user.id }
                    }),
                    fetch('/api/user/portfolio-value', {
                        headers: { 'user-id': user.id }
                    })
                ]);

                if (!profileRes.ok || !portfolioRes.ok) {
                    throw new Error('Failed to fetch data');
                }

                const [profileData, portfolioData] = await Promise.all([
                    profileRes.json(),
                    portfolioRes.json()
                ]);

                if (profileData.profilePicture) {
                    user.profilePicture = profileData.profilePicture;
                }
                setPortfolioValue(portfolioData.totalValue);
                setIsInitialLoad(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [user?.id, isInitialLoad]);

    const handleLogout = async () => {
        await fetch('/api/signout');
        localStorage.removeItem("user");
        logout();
        router.push('/auth');
    };

    const handleNavigation = (path: string) => {
        router.push(path);
        onClose();
    };

    return (
        <div className="fixed inset-0 isolate z-50 flex">
            <div className="fixed inset-0 bg-black/60 backdrop-blur-sm" onClick={onClose} />
            <motion.aside
                ref={panelRef}
                className="relative ml-auto w-[400px] h-screen bg-black shadow-2xl"
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
                {/* Fixed Header */}
                <div className="absolute top-0 left-0 right-0 z-10 bg-black p-6 border-b border-white/10">
                    <div className="flex justify-between items-center">
                        <button
                            onClick={() => handleNavigation('/')}
                            className="flex items-center space-x-2 text-white/80 hover:text-blue-400 transition-all duration-300"
                        >
                            <ArrowLeftIcon className="h-5 w-5" />
                            <span className="text-sm font-medium">BACK</span>
                        </button>
                        <Button
                            variant="ghost"
                            onClick={onClose}
                            className="text-white/80 hover:text-blue-400 transition-all duration-300"
                        >
                            <X className="h-5 w-5" />
                        </Button>
                    </div>
                </div>

                {/* Scrollable Content */}
                <div className="h-full pt-[88px] pb-6 overflow-y-auto scrollbar-hide">
                    <div className="px-6">
                        {/* User info section */}
                        <div className="relative mb-10 p-6 rounded-2xl bg-gradient-to-br from-blue-500/10 via-transparent to-purple-500/10 border border-white/10">
                            <div className="flex items-start justify-between mb-4">
                                <div className="flex flex-col">
                                    <h2 className="text-2xl font-bold text-white mb-1">
                                        Hey {getFormattedName(user?.name)}.
                                    </h2>
                                    <p className="text-sm text-gray-400">{user?.email}</p>
                                </div>
                                <Avatar className="w-14 h-14 ring-2 ring-blue-500/50 ring-offset-2 ring-offset-black transition-all duration-300 hover:ring-blue-400">
                                    <AvatarImage
                                        alt="User avatar"
                                        src={user?.profilePicture || "/reddit.webp"}
                                        className="object-cover"
                                    />
                                </Avatar>
                            </div>

                            {/* Portfolio Value Card */}
                            <div className="bg-black p-4 rounded-xl border border-white/10">
                                <div className="flex items-center justify-between mb-2">
                                    <span className="text-sm text-gray-400">Portfolio Value</span>
                                    <Badge className="bg-blue-500/20 text-blue-300 px-3">
                                        VERIFIED
                                    </Badge>
                                </div>
                                <div className="flex items-center justify-between">
                                    {isLoading ? (
                                        <div className="flex items-center space-x-2">
                                            <Loader2 className="h-5 w-5 animate-spin text-blue-400" />
                                            <span className="text-gray-400">Calculating...</span>
                                        </div>
                                    ) : (
                                        <>
                                            <p className="text-3xl font-bold text-white">
                                                ${portfolioValue.toLocaleString()}
                                            </p>
                                            <Button
                                                onClick={handleAddWatchClick}
                                                className="ml-4 bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 hover:text-blue-300 text-sm px-3 py-1 rounded-lg transition-all duration-300 flex items-center space-x-1"
                                                variant="ghost"
                                                size="sm"
                                            >
                                                <PlusIcon className="h-4 w-4" />
                                                <span>Add</span>
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Navigation menu */}
                        <nav className="space-y-2.5 mb-6">
                            {[
                                { label: "Portfolio", icon: WatchIcon, path: "/portfolio" },
                                { label: "Dashboard", icon: LayersIcon, path: "/dashboard", badge: "NEW" },
                                { label: "Charts", icon: BarChart3, path: "/charts", badge: "NEW" },
                                { label: "Purchases", icon: DollarSignIcon, path: "/purchases" },
                                { label: "Marketplace", icon: ShoppingBagIcon, path: "/marketplace" },
                                { label: "Settings", icon: SettingsIcon, path: "/settings" },
                            ].map((item) => (
                                <button
                                    key={item.label}
                                    onClick={() => handleNavigation(item.path)}
                                    className="flex items-center justify-between w-full p-4 rounded-xl bg-black hover:bg-blue-600/10 hover:shadow-lg hover:shadow-blue-500/10 border border-white/10 hover:border-blue-500/20 transition-all duration-300 group"
                                >
                                    <div className="flex items-center space-x-3">
                                        <item.icon className="h-5 w-5 text-gray-400 group-hover:text-blue-400" />
                                        <span className="text-gray-300 group-hover:text-white">
                                            {item.label}
                                        </span>
                                    </div>
                                    {item.badge && (
                                        <Badge className="bg-blue-500/20 text-blue-300">
                                            {item.badge}
                                        </Badge>
                                    )}
                                </button>
                            ))}
                        </nav>

                        {/* Logout section */}
                        <div>
                            <div className="p-4 rounded-xl bg-red-500/10 border border-red-500/20 mb-4">
                                <div className="flex items-center space-x-3 mb-2">
                                    <ShieldIcon className="h-5 w-5 text-red-400" />
                                    <span className="text-red-300 font-medium">Security Notice</span>
                                </div>
                                <p className="text-sm text-gray-400">
                                    Remember to always keep your account secure and never share your credentials.
                                </p>
                            </div>
                            <Button
                                onClick={handleLogout}
                                className="w-full bg-red-500/10 text-red-400 hover:bg-red-500/20 hover:text-red-300 transition-all duration-300"
                            >
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>
            </motion.aside>
        </div>
    );
}