import React, { useMemo } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import {
  BarChart3,
  Bell,
  Clock,
  ArrowRight
} from "lucide-react";
import TradingViewChart from "../../../components/component/portfolio/TradingViewChart";

export function HeroMid() {
  // Generate more realistic price data with trends and volatility
  const generatePriceData = () => {
    const data = [];
    let price = 15000;
    const volatility = 0.02;
    const trend = 0.001;
    const numDays = 180;

    for (let i = 0; i < numDays; i++) {
      const dailyReturn = (Math.random() - 0.5) * volatility + trend;
      price = price * (1 + dailyReturn);
      price = price * (1 + Math.sin(i / 30) * 0.01);

      data.push({
        t: Date.now() - (numDays - i) * 24 * 60 * 60 * 1000,
        p: price
      });
    }

    return data;
  };

  const exampleData = useMemo(() => generatePriceData(), []); // Memoize to prevent regeneration
  const currentPrice = useMemo(() => {
    const latestDataPoint = exampleData[exampleData.length - 1];
    return latestDataPoint.p.toFixed(0);
  }, [exampleData]);

  return (
    <main className="min-h-screen bg-black text-white">
      <div className="container mx-auto px-4 py-16">
        <Card className="bg-black border border-white/10 rounded-2xl overflow-hidden">
          <CardContent className="p-8">
            {/* Main Content */}
            <div className="grid lg:grid-cols-2 gap-12">
              {/* Left Column */}
              <div className="space-y-8">
                <div className="space-y-4">
                  <Badge className="bg-blue-500/20 text-blue-300 px-3 hover:bg-blue-500/30 transition-colors">
                    WATCH TRACKER
                  </Badge>
                  <h1 className="text-5xl font-bold text-white leading-tight">
                    Chart. Track.<br />
                    <span className="text-blue-400">Notify.</span>
                  </h1>
                </div>

                <p className="text-lg text-gray-400">
                  Get real-time price alerts for your favorite watches. Built by enthusiasts, for enthusiasts.
                </p>

                {/* Enhanced Chart Container */}
                <div className="relative group">
                  {/* Gradient Overlay */}
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-transparent to-transparent z-10 pointer-events-none" />

                  {/* Chart Background with Fancy Border */}
                  <div className="relative w-full h-64 bg-gradient-to-br from-slate-900/90 via-slate-900/50 to-blue-900/30 rounded-xl overflow-hidden border border-white/10 backdrop-blur-xl shadow-lg transform group-hover:scale-[1.02] transition-all duration-300">
                    {/* Fancy Corner Accents */}
                    <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-blue-500/30 rounded-tl-xl" />
                    <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-blue-500/30 rounded-br-xl" />

                    {/* Price Indicator */}
                    <div className="absolute top-4 right-4 bg-blue-500/10 backdrop-blur-md border border-blue-500/20 rounded-lg px-3 py-1.5 z-20">
                      <span className="text-blue-300 font-mono">${currentPrice.toLocaleString()}</span>
                    </div>

                    {/* Chart */}
                    <div className="absolute inset-0">
                      <TradingViewChart data={exampleData} height={256} />
                    </div>
                  </div>
                </div>

                <Link href="/portfolio" className="inline-block">
                  <Button className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-all duration-300 flex items-center space-x-2 shadow-lg shadow-blue-500/20">
                    <span>Start Tracking</span>
                    <ArrowRight className="h-5 w-5" />
                  </Button>
                </Link>
              </div>

              {/* Right Column */}
              <div className="grid gap-6">
                {[
                  {
                    icon: BarChart3,
                    title: "Price Analytics",
                    description: "Track historical price trends and market analysis with advanced charting tools"
                  },
                  {
                    icon: Bell,
                    title: "Instant Alerts",
                    description: "Get notified instantly when prices match your target through Telegram or email"
                  },
                  {
                    icon: Clock,
                    title: "Real-time Data",
                    description: "Access up-to-the-minute market information from trusted sources worldwide"
                  }
                ].map((feature, index) => (
                  <div
                    key={index}
                    className="group p-6 rounded-xl bg-gradient-to-br from-blue-500/10 via-transparent to-purple-500/10 border border-white/10 hover:border-blue-500/20 hover:bg-blue-500/5 transition-all duration-300 transform hover:-translate-y-1"
                  >
                    <div className="flex items-start space-x-4">
                      <feature.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300 transition-colors" />
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-blue-300 transition-colors">
                          {feature.title}
                        </h3>
                        <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </main>
  );
}

export default HeroMid;